import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { connect } from 'react-redux';
import ClientsSaysSection from '../../components/ClientsSaysSection/ClientsSaysSection';
import ServiceSection from '../../components/ServicesSection/ServicesSection';
import HeroSection from '../../components/HeroSection/HeroSection';
import ExplorePlans from './ExplorePlans';
import FrequentlyQuestion from './FrequentlyQuestion';
import { ToastContainer } from 'react-toastify';
import { compose } from 'redux';

const LandingPage = () => {
    return (
        <>
            <Header />
            <div>
                <HeroSection />
                <ServiceSection />
                <ClientsSaysSection />
                <ExplorePlans />
                <FrequentlyQuestion />
            </div>
            <ToastContainer />
            <Footer />
        </>
    )
};

const mapStateToProps = state => {
    const { user = {} } = state.user;
    return {
        user,
    }
};
// const mapDispatchToProps = dispatch = ({});

export default compose(connect(mapStateToProps))(LandingPage);