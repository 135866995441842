import React from 'react';
import css from './FrequentlyQuestion.module.scss';
import Collapsible from 'react-collapsible';
import Container from '../../components/Container/Container';

const FrequentlyQuestion = () => {
    return (
            <Container>
                <div className={css.privacyPolicyWrapper}>
                    <h1 className={css.title}>FAQ</h1>
                    <div className={css.faqContent}>
                        <div className={css.faqText}>
                            <Collapsible trigger={'Che cos\'è Dart?'}>
                                Dart è una piattaforma dedicata ai professionisti del settore dello spettacolo per semplificare il processo di booking per gestori di teatri, agenzie e artisti.
                            </Collapsible>
                        </div>
                        <div className={css.faqText}>
                            <Collapsible trigger={'Quali sono i vantaggi di utilizzare Dart?'}>
                                Il motore di ricerca di Dart permette di navigare l&apos;offerta nazionale di show e venue ottimizzando la ricerca selezionando i requisiti necessari. L&apos;utente può accedere a tutte le informazioni necessarie come contatti, dati tecnici e richieste economiche di venue e show. Dopo aver finalizzato gli accordi l&apos;utente può gestire gli accordi attraverso un unico portale.
                            </Collapsible>
                        </div>
                        <div className={css.faqText}>
                            <Collapsible trigger={'Quali sono i requisiti di sistema per utilizzare Dart?'}>
                                Dart è un web-based SaaS, significa che per utilizzarlo bastano una connessione ad internet e un browser, senza necessità di installare alcun software.
                            </Collapsible>
                        </div>
                        <div className={css.faqText}>
                            <Collapsible trigger={'Come posso registrarmi per ottenere un account?'}>
                                Per registrarsi basta cliccare sull&apos;icona profilo in alto a destra, selezionare “sign up” e seguire i passaggi guidati.
                            </Collapsible>
                        </div>
                        <div className={css.faqText}>
                            <Collapsible trigger={'Come posso ottenere supporto tecnico?'}>
                                Per contattare l&apos;assistenza è possibile usare il modulo presente nel footer del sito o scrivendo una email all&apos;indirizzo info@direzione.art.
                            </Collapsible>
                        </div>
                        <div className={css.faqText}>
                            <Collapsible trigger={'Il mio account è sicuro su Dart?'}>
                                Per maggiori informazioni visitare la pagina relativa alla nostra privacy policy.
                            </Collapsible>
                        </div>
                        <div className={css.faqText}>
                            <Collapsible trigger={'Posso provare Dart prima di impegnarmi?'}>
                                I servizi di Dart sono offerti gratuitamente. A breve sarà disponibile una versione premium opzionale con l&apos;aggiunta di alcuni servizi a pagamento.
                            </Collapsible>
                        </div>
                    </div>
                </div>
            </Container >
    );
};

export default FrequentlyQuestion;
