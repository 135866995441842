import colors from 'react-multi-date-picker/plugins/colors';
import Container from '../../components/Container/Container';
import IconCollection from '../../components/IconCollection/IconCollection';
import css from './ExplorePlans.module.scss';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";


const ExplorePlans = () => {
    const navigate = useNavigate();
    return (
        <Container className={css.explorePlansSection}>
            <h1 className={css.title}>Piani di abbonamento disponibili</h1>
            <h6 className={css.subTitle}>Scegli il piano di  abbonamento più adatto alle tue esigenze. Se desideri accedere a funzionalità personalizzate non esitare a contattarci via email all'indirizzo <strong>info@direnzione.art</strong>.</h6>
            <div>
                <div className={css.planCards}>
                    <div className={css.planCard}>
                        <div className={css.topContents}>
                            <h5>Piano gratuito</h5>
                            <h6> 0 €/mese</h6>
                            <button>Piano in corso</button>
                        </div>
                        <h4 className={css.allFeature}>Funzioni disponibili</h4>
                        <div  className={css.bottomContents}>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Invia 1 richiesta di booking al mese</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Richieste di booking in entrata illimitate</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Numero di schede create illimitato</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Accesso ai recapiti degli altri utenti</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_PINK" />
                                <p>Anteprima delle richieste economiche</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_PINK" />
                                <p>Accesso al calendly degli altri utenti</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_PINK" />
                                <p>Accesso ai codici di Copyright</p>
                            </div>
                        </div>
                    </div>
                    <div className={css.planCard}>
                        <div className={css.topContents}>
                            <h5>Piano professionista</h5>
                            <h6>9.99 €/mese</h6>
                            <button onClick={() => navigate("/my-subscription")}>Attiva</button>
                        </div>
                        <h4 className={css.allFeature}>Funzioni disponibili</h4>
                        <div  className={css.bottomContents}>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Invia 10 richiesta di booking al mese</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Richieste di booking in entrata illimitate</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Numero di schede create illimitato</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Accesso ai recapiti degli altri utenti</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Anteprima delle richieste economiche</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Accesso al calendly degli altri utenti</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Accesso ai codici di Copyright</p>
                            </div>
                        </div>
                    </div>
                    <div className={css.planCard}>
                        <div className={css.topContents}>
                            <h5>Piano azienda</h5>
                            <h6>19.99 €/mese</h6>
                            <button onClick={() => navigate("/my-subscription")}>Attiva</button>
                        </div>
                        <h4 className={css.allFeature}>Funzioni disponibili</h4>
                        <div  className={css.bottomContents}>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Invia 50 richiesta di booking al mese</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Richieste di booking in entrata illimitate</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Numero di schede create illimitato</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Accesso ai recapiti degli altri utenti</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Anteprima delle richieste economiche</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Accesso al calendly degli altri utenti</p>
                            </div>
                            <div className={css.tickContent}>
                                <IconCollection name="TICK_GREEN" />
                                <p>Accesso ai codici di Copyright</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={css.eventCard}></div>
            </div >
        </Container >
    );
};

export default ExplorePlans;